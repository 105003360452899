import { AnimatePresence, motion } from "framer-motion"
import React, { useRef, useState } from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function Nav() {
  const [openNav, setOpenNav] = useState(false)
  const [showServicesDropdown, setShowServicesDropdown] = useState(false)
  const dropdownTimeout = useRef(null)

  const containerVariants = {
    hidden: {
      height: "50px",
      opacity: 0,
      transition: {
        duration: 0.3,
        when: "afterChildren",
      },
    },
    visible: {
      height: "380px",
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  }

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  }

  const handleMouseEnter = () => {
    if (dropdownTimeout.current !== null) {
      clearTimeout(dropdownTimeout.current)
    }
    setShowServicesDropdown(true)
  }

  const handleMouseLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setShowServicesDropdown(false)
    }, 300)
  }

  return (
    <>
      <div className="mx-auto flex w-full max-w-[1340px] items-center justify-between rounded-lg bg-secondary/80 px-4 py-0.5 backdrop-blur-sm">
        <div className="absolute top-2.5 z-50 flex items-center gap-1 md:relative md:top-0">
          <Link to="/" className="w-max font-kanit text-xl md:text-2xl">
            Bessa Studio
          </Link>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:block">
          <ul className="flex">
            {[
              { to: "/", label: "Home" },
              {
                to: "/services",
                label: "Services",
                hasDropdown: true,
              },
              { to: "/about", label: "About" },
              { to: "/portfolio", label: "Portfolio" },
              { to: "/contact", label: "Contact" },
            ].map(({ to, label, hasDropdown }) => (
              <li
                key={label}
                className="relative my-auto"
                onMouseEnter={hasDropdown ? handleMouseEnter : undefined}
                onMouseLeave={hasDropdown ? handleMouseLeave : undefined}
              >
                <Link
                  to={to}
                  activeClassName="bg-surface"
                  className="rounded-lg px-2.5 py-1 font-kanit"
                >
                  {label}
                </Link>
                <AnimatePresence>
                  {hasDropdown && showServicesDropdown && (
                    <motion.ul
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={dropdownVariants}
                      className="absolute -left-[200%] top-[140%] mt-2 flex w-max flex-col overflow-hidden rounded-lg bg-secondary/80"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <li>
                        <Link
                          to="/services/web-development"
                          className="block p-4 hover:bg-secondary"
                        >
                          <div className="flex items-center">
                            <StaticImage
                              quality={100}
                              width={128}
                              className="mr-3 w-32 object-contain"
                              src="../images/heroSlider/Browser.png"
                              alt="Image 1"
                            />

                            <div className="flex flex-col">
                              <span className="font-kanit text-xl">
                                Web Development
                              </span>
                              <span className="text-gray-600 text-sm">
                                Custom web solutions
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/support"
                          className="block p-4 hover:bg-secondary"
                        >
                          <div className="flex items-center">
                            <StaticImage
                              quality={100}
                              width={128}
                              className="mr-3 w-32 object-contain"
                              src="../images/heroSlider/Browser-1.png"
                              alt="Image 1"
                            />
                            <div className="flex flex-col">
                              <span className="font-kanit text-xl">
                                Web Maintenance
                              </span>
                              <span className="text-gray-600 text-sm">
                                Ongoing support & updates
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </motion.ul>
                  )}
                </AnimatePresence>
              </li>
            ))}
            <li>
              <a
                className="ml-1 w-max rounded-md border-2 border-accent-secondary bg-accent-secondary px-4 py-1 font-inter font-medium text-dark transition duration-200 hover:shadow-[4px_4px_0px_0px_#BAF40050] md:py-1.5"
                href="https://cal.com/ronald-bessa"
              >
                Book a call
              </a>
            </li>
          </ul>
        </nav>

        {/* Mobile Navigation */}
        <motion.div
          initial="hidden"
          animate={openNav ? "visible" : "hidden"}
          variants={containerVariants}
          className="relative flex w-full justify-between px-2 py-1.5 md:hidden"
        >
          <AnimatePresence>
            {openNav && (
              <motion.nav
                className="mx-auto my-auto"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={containerVariants}
              >
                <ul className="text-white flex flex-col gap-3 pt-14 text-center">
                  {[
                    { to: "/", label: "Home" },
                    { to: "/services", label: "Services" },
                    {
                      to: "/services/web-development",
                      label: "Web development",
                    },
                    { to: "/services/support", label: "Web maintenance" },
                    { to: "/portfolio", label: "Portfolio" },
                    { to: "/about", label: "About" },
                    { to: "/contact", label: "Contact" },
                  ].map(({ to, label }) => (
                    <motion.li
                      key={label}
                      onClick={() => setOpenNav(false)}
                      variants={itemVariants}
                      className="my-auto"
                    >
                      <Link
                        to={to}
                        activeClassName="bg-surface"
                        className="rounded-lg px-2.5 py-1 font-kanit text-xl"
                      >
                        {label}
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              </motion.nav>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Hamburger Button */}
        <button
          aria-label="Menu"
          onClick={() => setOpenNav(prev => !prev)}
          className="z-50 mb-auto mt-4 flex h-5 w-8 flex-col justify-center gap-1.5 md:hidden"
        >
          <motion.span
            animate={openNav ? { rotate: 45, y: 4 } : { rotate: 0, y: 0 }}
            transition={{ duration: 0.3 }}
            className="block h-[2px] w-full rounded-full bg-light"
          />
          <motion.span
            animate={openNav ? { rotate: -45, y: -4 } : { rotate: 0, y: 0 }}
            transition={{ duration: 0.3 }}
            className="block h-[2px] w-full rounded-full bg-light"
          />
        </button>
      </div>
    </>
  )
}
