import CookieConsent from "react-cookie-consent"
import React from "react"

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom" // Display the banner at the bottom of the page
      buttonText="Accept" // Text for the accept button
      declineButtonText="Decline" // Text for the decline button
      enableDeclineButton // Enable the decline button
      cookieName="gatsby-gdpr-google-analytics" // Name of the cookie to store the user's choice
      overlay // Add an overlay to the page when the banner is visible
      style={{ background: "#2B373B" }} // Customize the banner's background color
      buttonStyle={{ background: "#4CAF50", color: "#FFF", fontSize: "14px" }} // Customize the accept button
      declineButtonStyle={{
        background: "#FF0000",
        color: "#FFF",
        fontSize: "14px",
      }} // Customize the decline button
      expires={365} // Cookie expiration in days (1 year)
      onAccept={() => {
        // Enable Google Analytics if the user accepts
        if (window.gtag) {
          window.gtag("consent", "update", {
            analytics_storage: "granted",
          })
        }
        console.log("Cookies accepted!")
      }}
      onDecline={() => {
        // Disable Google Analytics if the user declines
        if (window.gtag) {
          window.gtag("consent", "update", {
            analytics_storage: "denied",
          })
        }
        console.log("Cookies declined!")
      }}
    >
      This website uses cookies to enhance the user experience. By continuing to
      browse, you agree to our use of cookies.{" "}
      <a href="/privacy-policy" style={{ color: "#FFF" }}>
        Learn more
      </a>
    </CookieConsent>
  )
}

export default CookieConsentBanner
