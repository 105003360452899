import { Link } from "gatsby"
import React from "react"

const Footer = () => {
  const email = "ronald@bessa.studio"
  const socialLinks = [
    { name: "LinkedIn", url: "https://www.linkedin.com/in/ronaldbessa/" },
    { name: "Github", url: "https://github.com/rnldbss" },
  ]
  const servicesLinks = [
    { name: "Web Development", to: "/services/web-development" },
    { name: "Web Maintenance", to: "/services/support" },
  ]
  const studioLinks = [
    { name: "Portfolio", to: "/portfolio" },
    { name: "About", to: "/about" },
    { name: "Contact", to: "/contact" },
  ]
  const legalLinks = [
    { name: "Privacy Policy", to: "/legal/privacy-policy" },
    { name: "Terms", to: "/legal/terms" },
  ]

  return (
    <footer className="relative z-10 pt-20 bg-grid-light/10 md:pt-40">
      <div className="mx-auto max-w-[1400px] rounded-2xl bg-primary px-5 pt-5 md:px-10 md:pt-10">
        <div className="flex flex-col justify-between gap-10 md:flex-row">
          {/* Contact Section */}
          <div>
            <h2>Let's talk</h2>
            <a
              href={`mailto:${email}`}
              className="relative z-20 bg-gradient-to-br from-accent-secondary from-[20%] to-[#1DB954] bg-clip-text font-kanit text-3xl font-medium text-transparent md:font-normal"
            >
              {email}
            </a>
          </div>

          {/* Links Section */}
          <div className="flex gap-10 md:gap-16">
            {/* Services Links */}
            <ul className="flex flex-col space-y-1">
              <li className="mb-2 font-medium">Services</li>
              {servicesLinks.map(link => (
                <li key={link.name}>
                  <Link
                    to={link.to}
                    className="text-light/50 duration-200 hover:text-light"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>

            {/* Social Links */}
            <ul className="flex flex-col space-y-1">
              <li className="mb-2 font-medium">Social</li>
              {socialLinks.map(link => (
                <li key={link.name}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light/50 duration-200 hover:text-light"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>

            {/* Studio Links */}
            <ul className="flex flex-col space-y-1">
              <li className="mb-2 font-medium">Studio</li>
              {studioLinks.map(link => (
                <li key={link.name}>
                  <Link
                    to={link.to}
                    className="text-light/50 duration-200 hover:text-light"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Legal Section */}
        <div className="flex justify-between pb-2 pt-16 md:pb-4 md:pt-28">
          <span className="text-xs text-light/50">© 2023 Bessa Studio.</span>
          <ul className="flex gap-5">
            {legalLinks.map(link => (
              <li key={link.name}>
                <Link
                  to={link.to}
                  className="text-xs text-light/50 duration-200 hover:text-light"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
