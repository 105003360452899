import { Link } from "gatsby"
import { MdOutlineChevronRight } from "react-icons/md"
import React from "react"

export default function Breadcrumb({ items }) {
  return (
    <div className="mx-auto hidden max-w-[1340px] items-center space-x-1.5 px-4 py-1 backdrop-blur-sm md:flex">
      <Link
        className="text-xs text-light/90 duration-200 ease-out hover:text-light"
        to="/"
      >
        Home
      </Link>

      {items.map((item, index) => (
        <React.Fragment key={index}>
          <span className="text-light/40">/</span>
          {item.path ? (
            <Link
              to={item.path}
              className="text-xs text-light/90 duration-200 ease-out hover:text-light"
            >
              {item.name}
            </Link>
          ) : (
            <span className="text-xs text-light/90">{item.name}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
