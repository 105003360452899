import Breadcrumb from "./Breadcrumb"
import CookieConsentBanner from "./CookieConsentBanner"
import Footer from "../Footer"
import { Nav } from "../Nav"
import React from "react"

export default function Layout({ children, location }) {
  // Check if the current page is the home page
  const isHomePage = location.pathname === "/"

  // Dynamically generate breadcrumb items based on the URL
  const pathSegments = location.pathname.split("/").filter(Boolean) // Split URL into segments

  const formatBreadcrumbName = segment => {
    // Replace hyphens with spaces and capitalize each word
    return segment
      .replace(/-/g, " ") // Replace all hyphens with spaces
      .split(" ") // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ") // Join words back into a single string
  }

  const breadcrumbItems = pathSegments.map((segment, index) => ({
    name: formatBreadcrumbName(segment), // Format the segment name
    path:
      index === pathSegments.length - 1
        ? null
        : `/${pathSegments.slice(0, index + 1).join("/")}`,
  }))

  return (
    <main className="relative">
      <header className="fixed  top-4 z-50 mx-auto  w-full  px-2">
        <Nav />
        {!isHomePage && <Breadcrumb items={breadcrumbItems} />}
      </header>

      {children}
      <Footer />
      <CookieConsentBanner />
    </main>
  )
}
